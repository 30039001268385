<template>
  <div>
    <div class="agGrid-page container">
      <base-header>
        <refresh-analytics type="commissions" />
      </base-header>
      <base-ag-grid
        :columnDefs="columnDefs"
        :datasource="datasource"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import RefreshAnalytics from '@/agGridV2/components/header/refresh-analytics.component.vue'
import CommissionPopup from '@/agGridV2/components/commission-popup.component.vue'
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'

export default {
  components: {
    BaseHeader,
    BaseAgGrid,
    RefreshAnalytics
  },
  data() {
    return {
      datasource: null,
      columnDefs: null
    }
  },
  props: {
    activityTagName: null
  },
  async mounted() {
    function generateDatesArray() {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth() + 1
      const dates = []
      for (let year = 2021; year <= currentYear; year++) {
        const startMonth = (year === 2021) ? 1 : 0;
        const endMonth = (year === currentYear) ? currentMonth : 12;
        for (let month = startMonth; month < endMonth; month++) {
          const dateString = `${month}_${year}`
          dates.push(dateString)
        }
      }
      return dates
    }
    const dates = generateDatesArray()
    const datesColumnDefs = []
    for (const date of dates) {
      const [ month, year ] = date.split('_')
      datesColumnDefs.push({
        field: `${date}`,
        headerName: SimpleHelper.monthsLabels[month] + ' ' + year,
        filter: false,
        sortable: false,
        cellRenderer: (params) => {
          const btn = document.createElement('div')
          btn.textContent = SimpleHelper.priceFormatter(params.value)
          btn.style.width = 'calc(100% + 16px)'
          btn.style.cursor = 'pointer'
          btn.style.margin = '-0px -9px 0 -9px'
          btn.style.padding = '0 9px'
          btn.addEventListener('mouseover', () => {
            btn.style.backgroundColor = '#7987FF'
            btn.style.color = '#ffffff'
          })
          btn.addEventListener('mouseout', () => {
            btn.style.backgroundColor = 'transparent'
            btn.style.color = '#000000'
          })
          btn.addEventListener('click', () => {
            this.$modal.show(
              CommissionPopup,
              {
                month: params.colDef.field,
                staff_id: params.data._id
              }, { width: '500px', height: '500px' }
            )
          })
          return btn
        }
      })
    }
    this.columnDefs = [
      {
        field: 'name',
        headerName: 'Name',
        pinned: 'left'
      },
      ...datesColumnDefs
    ]
    this.datasource = {
      getRows: (params) => {
        params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
        params.request['activityTagName'] = this.activityTagName
        MtApi.agGridCommission(params.request).then((response) => {
          this.$store.dispatch('setAgGridRowsCount', { page: this.$route.name, query: response.count })
          this.$emitter.emit('set_agGrid_rows_count', {})
          params.success({ rowData: response.rows, rowCount: response.count })
        })
      }
    }
  }
}
</script>
