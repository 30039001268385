<template>
  <div class="page-accounting">
    <accounting-navigation />
    <div class="single-page container">
      <b-row>
        <b-col cols="4">
          <b-form-group label="Month">
            <custom-select
              :value="selectedDate"
              :options="period"
              :datepicker="true"
              @input="changeDate"
            ></custom-select>
          </b-form-group>
          <b-form-group class="amount" label="Enter the invoice amount">
            <b-form-input 
              v-model="invoiceAmount" 
              type="number"
              placeholder="Enter amount">
            </b-form-input>
          </b-form-group>
          <b-button @click="splitInvoice" variant="primary">Split
            <b-spinner v-if="loading" style="width: 15px; height: 15px; border: 0.25em solid currentcolor; border-right-color: transparent;" label="Loading..."></b-spinner>
          </b-button>
        </b-col>
        <b-col cols="8">
          <div class="project-section">
            <h5>Client Projects</h5>
            <ul>
              <li>SEO: {{ priceFormatter(clientProjectsSeo) }} (PM: {{ priceFormatter(clientProjectsSeoPm) }})</li>
              <li>Web Design & Development: {{ priceFormatter(clientProjectsWebDesignDevelopment) }} (PM: {{ priceFormatter(clientProjectsWebDesignDevelopmentPm) }})</li>
            </ul>
          </div>
          <div class="project-section">
            <h5>Internal Projects</h5>
            <ul>
              <li>SEO: {{ priceFormatter(internalProjectsSeo) }} (PM: {{ priceFormatter(internalProjectsSeoPm) }})</li>
              <li>Tools & Web Design/Dev: {{ priceFormatter(internalProjectsToolsAndWebDesign) }} (PM: {{ priceFormatter(internalProjectsToolsAndWebDesignPm) }})</li>
            </ul>
          </div>
          <!-- <div style="white-space: pre;">{{ allData }}</div> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { showSnackbar } from '@/services'
import { monthYear, yearMonthDay } from '@/helpers/dateFormats'
import dayjs from 'dayjs'
// Components
import CustomSelect from '@/components/ui/CustomSelect'
import AccountingNavigation from '@/components/AccountingNavigation.vue'
import { makePeriod } from '@/mixins/period'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'

export default {
  components: {
    AccountingNavigation,
    CustomSelect
  },
  data() {
    return {
      loading: false,
      selectedDate: null,
      invoiceAmount: null,
      clientProjectsSeo: 0,
      clientProjectsWebDesignDevelopment: 0,
      internalProjectsSeo: 0,
      internalProjectsToolsAndWebDesign: 0,
      clientProjectsSeoPm: 0,
      clientProjectsWebDesignDevelopmentPm: 0,
      internalProjectsSeoPm: 0,
      internalProjectsToolsAndWebDesignPm: 0,
      allData: null
    }
  },
  computed: {
    period() {
      const period = makePeriod('month', true);
      let newArray = period.slice(1);
      return newArray
    }
  },
  async mounted() {
    this.selectedDate = {
      label: dayjs().subtract(1, 'month').format(monthYear),
      code: dayjs().subtract(1, 'month').startOf('month').format(yearMonthDay)
    }
  },
  methods: {
    makePeriod,
    priceFormatter(price) {
      return SimpleHelper.priceFormatter(price, true)
    },
    async splitInvoice() {
      if (!this.invoiceAmount) {
        showSnackbar({
          text: 'Inovice amount not set!',
          mode: 'error'
        })
        return false
      }

      const analytics = await MtApi.agGridProjectsAnalyticsV2();
      const analyticsData = analytics.data;
      const [year, month, day] = this.selectedDate.code.split('/');
      const formatedMonth = `${month.replace('0', '')}_${year}`;

      const getStatistic = (type) => {
        const projects = analyticsData.filter((ad) => type.includes(ad.type));
        const statistic = []
        for (const project of projects) {
          const statisticForMonth = project.statistic.filter((s) => s.month === formatedMonth);
          if (statisticForMonth && statisticForMonth.length > 0) {
            statistic.push({
              project: project.abbr_l,
              ...statisticForMonth[0].activity_tags
            });
          }
        }

        return statistic
      }

      const getStatisticSumByKeys = (keys, mode = 'exclude', statistic) => {
        let sum = 0;
        for (const stat of statistic) {
          if (mode === 'exclude') {
            sum += Object.keys(stat)
            .filter(key => !keys.includes(key))
            .reduce((acc, key) => acc + stat[key]['expenses_amount'], 0)
          } else if (mode === 'include') {
            sum += Object.keys(stat)
            .filter(key => keys.includes(key))
            .reduce((acc, key) => acc + stat[key]['expenses_amount'], 0)
          }
        }
        return parseFloat(sum.toFixed(0));
      }

      this.loading = true

      const globalData = {}

      // Clients Projects SEO Fact Sum
      await new Promise((resolve) => {
        (async () => {
          const statistic = getStatistic(['SEO']);
          globalData['clientProjectsSeoFactSum'] = getStatisticSumByKeys(['project', 'account_management', 'link_building_external', 'copywriting_external'], 'exclude', statistic);
          globalData['clientProjectsSeoFactSumPm'] = getStatisticSumByKeys(['project_management'], 'include', statistic);
          resolve(true)
        })()  
      })

      // Clients Projects Web Design Development Fact Sum
      await new Promise((resolve) => {
        (async () => {
          const statistic = getStatistic(['MNT', 'DEV']);
          globalData['clientProjectsWebDesignDevelopmentFactSum'] = getStatisticSumByKeys(['project', 'account_management'], 'exclude', statistic);
          globalData['clientProjectsWebDesignDevelopmentFactSumPm'] = getStatisticSumByKeys(['project_management'], 'include', statistic);
          resolve(true)
        })()  
      })

      // Internal Projects Seo Fact Sum
      await new Promise((resolve) => {
        (async () => {
          const statistic = getStatistic(['SEO-INT']);
          globalData['internalProjectsSeoFactSum'] = getStatisticSumByKeys(['project', 'link_building_external', 'copywriting_external'], 'exclude', statistic);
          globalData['internalProjectsSeoFactSumPm'] = getStatisticSumByKeys(['project_management'], 'include', statistic);
          resolve(true)
        })()  
      })

      // Internal Projects Tools And Web Design Fact Sum
      await new Promise((resolve) => {
        (async () => {
          const statistic = getStatistic(['DEV-INT']);
          globalData['internalProjectsToolsAndWebDesignFactSum'] = getStatisticSumByKeys(['project'], 'exclude', statistic);
          globalData['internalProjectsToolsAndWebDesignFactSumPm'] = getStatisticSumByKeys(['project_management'], 'include', statistic);
          resolve(true)
        })()  
      })

      // Total Fact Sum
      await new Promise((resolve) => {
        (async () => {
          globalData['totalFactSum'] = globalData['clientProjectsSeoFactSum'] + globalData['clientProjectsWebDesignDevelopmentFactSum'] + globalData['internalProjectsSeoFactSum'] + globalData['internalProjectsToolsAndWebDesignFactSum']
          resolve(true)
        })()  
      })

      // Persect of totalFactSum
      await new Promise((resolve) => {
        (async () => {
          // Other
          globalData['clientProjectsSeoPercentOfTotal'] = parseFloat((globalData['clientProjectsSeoFactSum'] / globalData['totalFactSum']).toFixed(2))
          globalData['clientProjectsWebDesignDevelopmentPercentOfTotal'] = parseFloat((globalData['clientProjectsWebDesignDevelopmentFactSum'] / globalData['totalFactSum']).toFixed(2))
          globalData['internalProjectsPercentOfTotal'] = parseFloat((globalData['internalProjectsSeoFactSum'] / globalData['totalFactSum']).toFixed(2))
          globalData['internalProjectsToolsAndWebDesignPercentOfTotal'] = parseFloat((globalData['internalProjectsToolsAndWebDesignFactSum'] / globalData['totalFactSum']).toFixed(2))

          // Pm
          globalData['clientProjectsSeoPercentOfTotalPm'] = parseFloat((globalData['clientProjectsSeoFactSumPm'] / globalData['totalFactSum']).toFixed(2))
          globalData['clientProjectsWebDesignDevelopmentPercentOfTotalPm'] = parseFloat((globalData['clientProjectsWebDesignDevelopmentFactSumPm'] / globalData['totalFactSum']).toFixed(2))
          globalData['internalProjectsPercentOfTotalPm'] = parseFloat((globalData['internalProjectsSeoFactSumPm'] / globalData['totalFactSum']).toFixed(2))
          globalData['internalProjectsToolsAndWebDesignPercentOfTotalPm'] = parseFloat((globalData['internalProjectsToolsAndWebDesignFactSumPm'] / globalData['totalFactSum']).toFixed(2))

          resolve(true)
        })()  
      })

      // Split
      await new Promise((resolve) => {
        (async () => {
          // Simple
          globalData['clientProjectsSeoSplit'] = parseFloat((this.invoiceAmount * globalData['clientProjectsSeoPercentOfTotal']).toFixed(2))
          globalData['clientProjectsWebDesignDevelopmentSplit'] = parseFloat((this.invoiceAmount * globalData['clientProjectsWebDesignDevelopmentPercentOfTotal']).toFixed(2))
          globalData['internalProjectsSplit'] = parseFloat((this.invoiceAmount * globalData['internalProjectsPercentOfTotal']).toFixed(2))
          globalData['internalProjectsToolsAndWebDesignSplit'] = parseFloat((this.invoiceAmount * (1 - (globalData['clientProjectsSeoPercentOfTotal'] + globalData['clientProjectsWebDesignDevelopmentPercentOfTotal'] + globalData['internalProjectsPercentOfTotal']))).toFixed(2))

          this.clientProjectsSeo = globalData['clientProjectsSeoSplit']
          this.clientProjectsWebDesignDevelopment = globalData['clientProjectsWebDesignDevelopmentSplit']
          this.internalProjectsSeo = globalData['internalProjectsSplit']
          this.internalProjectsToolsAndWebDesign = globalData['internalProjectsToolsAndWebDesignSplit']

          // Pm
          globalData['clientProjectsSeoSplitPm'] = parseFloat((this.invoiceAmount * globalData['clientProjectsSeoPercentOfTotalPm']).toFixed(2))
          globalData['clientProjectsWebDesignDevelopmentSplitPm'] = parseFloat((this.invoiceAmount * globalData['clientProjectsWebDesignDevelopmentPercentOfTotalPm']).toFixed(2))
          globalData['internalProjectsSplitPm'] = parseFloat((this.invoiceAmount * globalData['internalProjectsPercentOfTotalPm']).toFixed(2))
          globalData['internalProjectsToolsAndWebDesignSplitPm'] = parseFloat((this.invoiceAmount * globalData['internalProjectsToolsAndWebDesignPercentOfTotalPm']).toFixed(2))

          this.clientProjectsSeoPm = globalData['clientProjectsSeoSplitPm']
          this.clientProjectsWebDesignDevelopmentPm = globalData['clientProjectsWebDesignDevelopmentSplitPm']
          this.internalProjectsSeoPm = globalData['internalProjectsSplitPm']
          this.internalProjectsToolsAndWebDesignPm = globalData['internalProjectsToolsAndWebDesignSplitPm']

          resolve(true)
        })()  
      })

      this.allData = globalData;

      this.loading = false
    },
    changeDate(date) {
      this.selectedDate = date
    }
  }
}
</script>

<style lang="scss" scoped>
.amount {
  input {
    padding: 10px;
    height: 44px;
    border: 1px solid #E9E9E9;
    border-radius: 0;
    border-color: #bbbbbb;
  }
}
fieldset {
  padding: 0;
}

::v-deep fieldset > .col-form-label {
  font-weight: bold;
  font-size: 1rem;
}

.project-section {
  margin-bottom: 20px;

  h5 {
    font-weight: bold;
    font-size: 1rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;

    li {
      margin-bottom: 5px;
    }
  }
}
</style>